export default {
  pt: {
    Su: "Do",
    Mo: "Se",
    Tu: "Te",
    We: "Qa",
    Th: "Qi",
    Fr: "Sx",
    Sa: "Sa",
    Sun: "Dom",
    Mon: "Seg",
    Tue: "Ter",
    Wed: "Qua",
    Thu: "Qui",
    Fri: "Sex",
    Sat: "Sab",
    January: "Janeiro",
    February: "Fevereiro",
    March: "Março",
    April: "Abril",
    May: "Maio",
    June: "Junho",
    July: "Julho",
    August: "Agosto",
    September: "Setembro",
    October: "Outubro",
    November: "Novembro",
    December: "Dezembro",
    Jan: "Jan",
    Feb: "Fev",
    Mar: "Mar",
    Apr: "Abr",
    Jun: "Jun",
    Jul: "Jul",
    Aug: "Ago",
    Sep: "Set",
    Oct: "Out",
    Nov: "Nov",
    Dec: "Dez",
    Today: "Hoje",
    from:"de",
    to:"até"
  },
  en: {
    Su: "Su",
    Mo: "Mo",
    Tu: "Tu",
    We: "We",
    Th: "Th",
    Fr: "Fr",
    Sa: "Sa",
    Sun: "Sun",
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Jan: "Jan",
    Feb: "Feb",
    Mar: "May",
    Apr: "Apr",
    Jun: "Jun",
    Jul: "Jul",
    Aug: "Aug",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    Today: "Today",
    from:"from",
    to:"to"
  },
};
