<template>
  <section>
    <div class="modal fade in" ref="alarmAckForm">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ $t("alarm_acknowledgement") }}</h4>
          </div>
          <div class="modal-body">
            <EquipmentAlarmAConfigurationTable
              :full="false"
              :alarms="alarms"
              :panelOptions="{ showLine: false }"
              @notificationButtonClicked="
                $emit('notificationButtonClicked', $event)
              "
              style="margin-bottom:10px;overflow-x:auto;"
            />
            <EquipmentAlarmUserForm
              v-if="requires.execution != 'none'"
              :requires="requires.execution"
              :title="$t('execution')"
              v-model="status.execution"
            />
            <EquipmentAlarmUserForm
              v-if="requires.verification != 'none'"
              :requires="requires.verification"
              :title="$t('verification')"
              v-model="status.verification"
            />
          </div>
          <div class="modal-footer text-right">
            <button
              class="btn btn-primary pull-right"
              :disabled="!isValid"
              @click="ack"
            >
              <i class="fa fa-check"></i> {{ $t("acknowledge") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import EquipmentAlarmAConfigurationTable from "@/components/equipment-alarm-configuration-table";
import EquipmentAlarmUserForm from "@/components/equipment-alarm-user-form";
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "EquipmentAlarmAcknowledgementForm",
  components: {
    EquipmentAlarmUserForm,
    EquipmentAlarmAConfigurationTable
  },
  mixins: [MixinAlert],
  props: {
    alarms: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    panelOptions: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      status: {
        execution: null,
        verification: null
      },
      alert: null
    };
  },
  computed: {
    alarm: function() {
      return (this.alarms && this.alarms.length && this.alarms[0]) || null;
    },
    acknowledgement() {
      // TODO: mockup returned for simulation purposes
      // return {
      //   enabled: true,
      //   requires: {
      //     execution: "both",
      //     verification: "both"
      //   }
      // };
      var options = this.panelOptions;
      if (options && "acknowledgement" in options) {
        if (
          "enabled" in options.acknowledgement &&
          options.acknowledgement.enabled == false
        ) {
          return null;
        }
        return options.acknowledgement;
      }
      return null;
    },
    requires: function() {
      var ret = null;
      var def_acknowledgement = {
        execution: "none", // none || user || remark || both
        verification: "none" // none || user || remark || both
      };
      if (this.acknowledgement && "requires" in this.acknowledgement) {
        ret = {
          ...def_acknowledgement,
          ...this.acknowledgement.requires
        };
      } else {
        ret = def_acknowledgement;
      }
      return ret;
    },
    requireExecution: function() {
      let self = this;
      return (
        (self.requires &&
          self.requires.execution &&
          self.requires.execution != "none") ||
        false
      );
    },
    requireVerification: function() {
      let self = this;
      return (
        (self.requires &&
          self.requires.verification &&
          self.requires.verification != "none") ||
        false
      );
    },
    isValid: function() {
      let self = this;
      return (!self.status.execution && self.requireExecution) ||
        (!self.status.verification && self.requireVerification) ||
        (self.status.verification &&
          "user" in self.status.verification &&
          "user" in self.status.execution &&
          self.status.verification.user == self.status.execution.user) ||
        (self.status.verification &&
          "remark" in self.status.verification &&
          "remark" in self.status.execution &&
          self.status.verification.remark == self.status.execution.remark)
        ? false
        : true;
    }
  },
  methods: {
    ack: function() {
      let self = this;
      if (self.isValid) {
        let alarm = self.alarm;
        if (!alarm) return;
        let acknowledgement = {
          message: self.$t("acknowledgement_execution_requested")
        };
        if (self.status) {
          if (self.status.execution) {
            acknowledgement.execution = self.status.execution;
          }
          if (self.status.verification) {
            acknowledgement.verification = self.status.verification;
          }
        }
        var payload = [
          {
            command_type_id:
              self.$root.config.references.command_types.ack_writing,
            parameters: {
              alarm_configuration_id: alarm.id
            },
            user_data: {
              acknowledgement: acknowledgement
            }
          }
        ];
        self.$store.dispatch("command/save", payload).then((result) => {
          if (
            typeof result == "object" &&
            result.length &&
            result[0]?.value?.command_state?.id == 6 // carefully - it is the django id and not the identity number (not yet returned by the api)
            // should it be validated against self.$root.config.references.command_states.ready (2)
          ) {
            // TODO fetch to the new alarm_state endpoint
            if (self.validateSaveResponse({ ok: result })) {
              self.$store.dispatch("dashboard/setAlarmValue", {
                alarm_state_id: 3, // ACK .$root.config.references
                id: alarm.id,
                is_active: alarm?.alarm_current_state?.state
              });
              self.showAlert(function() {
                $(self.$refs.alarmAckForm).modal("hide");
              });
            } else {
              self.showAlert(function() {});
            }
          }
        });
      } else {
        $(self.$refs.alarmAckForm).modal("hide");
      }
    }
  },
  mounted: function() {
    let self = this;
    $(self.$refs.alarmAckForm)
      .on("hide.bs.modal", function() {
        self.$emit("hide");
      })
      .on("shown.bs.modal", function() {
        self.$emit("show");
      })
      .modal("show");
  }
};
</script>
<style scoped></style>
