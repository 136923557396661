<template>
  <div :style="{ padding: `${isEquipmentDashboard ? '0' : '20px 0'}` }">
    <div class="row">
      <div
        :class="{
          'col-sm-6': inputSearch,
          'col-md-8': isEquipmentDashboard,
          'col-md-6 text-right': !isEquipmentDashboard,
          'col-xs-12': !inputSearch
        }"
        v-if="showFilterOptions"
      >
        <div
          class="filter-options hidden-sm hidden-xs"
          :class="{ 'filter-options-small': isEquipmentDashboard }"
        >
          <div
            class="filter-option clicable"
            :class="{ active: item == filterOption }"
            v-for="(item, ix1) in filterOptions"
            :key="ix1 + 1000"
            :title="$t(`hints.${item}`)"
            @click.stop.prevent="filterOption = item"
          >
            <i
              :class="
                item == filterOption ? 'fa fa-dot-circle-o' : 'fa fa-circle-o'
              "
            ></i>
            {{ $tc(item, 1) }}
          </div>
        </div>
        <div
          class="form-group form-group-sm hidden-md hidden-lg"
          style="margin-top: -5px;"
        >
          <div class="input-group">
            <div class="input-group-addon"><i class="fa fa-filter"></i></div>
            <select v-model="filterOption" class="form-control">
              <option
                :value="item"
                v-for="(item, ix2) in filterOptions"
                :key="ix2 + 2000"
                >{{ $tc(item, 1) }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-sm-6 col-md-4': showFilterOptions,
          'col-sm-6 col-md-4 col-md-offset-8':
            !showFilterOptions && isEquipmentDashboard,
          'col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3':
            !showFilterOptions && !isEquipmentDashboard
        }"
        v-if="inputSearch"
      >
        <div>
          <div class="form-group form-group-sm input-search">
            <template v-if="alarmLevelOptions.length">
              <div class="input-group">
                <div class="input-group-addon btn" style="padding: 0">
                  <button
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    :title="(query_level && query_level.label.value) || ''"
                  >
                    <template
                      v-if="
                        query_level &&
                          (query_level.icon.show || query_level.label.value)
                      "
                    >
                      <span
                        v-if="query_level.icon.show"
                        :class="query_level.icon.class"
                        :style="query_level.icon.style"
                      ></span>
                      <span>
                        {{ alarmLevelOptionLabel(query_level) }}
                      </span>
                    </template>
                    <span v-else class="fa fa-exclamation-triangle"> </span>
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      v-for="option in alarmLevelOptions"
                      :key="option.label.value"
                      @click.prevent="query_level = option"
                    >
                      <a href="" @click.prevent="query_level = option">
                        <i
                          v-if="option.icon.show"
                          :class="option.icon.class"
                          :style="option.icon.style"
                        />
                        {{ alarmLevelOptionLabel(option) }}
                      </a>
                    </li>
                    <li
                      v-if="query_level"
                      @click.prevent="query_level = null"
                      style="border-top: 1px solid #dadada; padding-top: 4px;"
                    >
                      <a href="" @click.prevent="query_level = null">
                        <i class="fa fa-undo"></i> {{ $t("restore") }}
                      </a>
                    </li>
                  </ul>
                </div>
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('search')"
                  v-model="query_string"
                  ref="query_string"
                />
              </div>
            </template>
            <template v-else>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('search')"
                v-model="query_string"
                ref="query_string"
              />
            </template>
            <div class="input-reset">
              <span
                class="fa fa-close clicable"
                @click="resetQuery"
                v-if="query_string || query_level"
              ></span>
              <span class="fa fa-search text-gray" v-else></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { defaultAlarmLevelOption } from "@/components/registration/alarm-level-form.vue";
import { alarmDefs } from "@/services/alarm.js";

export default {
  name: "EquipmentAlarmSearchBar",
  props: {
    alarmList: {
      type: Array,
      required: false,
      default: () => []
    },
    panel: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    query_string: "",
    query_level: null
  }),
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    panelOptions() {
      return this?.panel?.options || {};
    },
    inputSearch() {
      return this?.panelOptions?.filter?.inputSearch || false;
    },
    filterOption: {
      set(name) {
        this.panelOptions.filter = this.panelOptions.filter || {};
        this.$set(this.panelOptions.filter, "selected", name);
      },
      get() {
        return (
          this?.panelOptions?.filter?.selected ||
          this.$root.config?.alarm?.filter?.default ||
          "all"
        );
      }
    },
    filterOptions() {
      return (this?.panelOptions?.filter?.items || [])
        .filter((item) => item.visible)
        .map((item) => item.title);
    },
    showFilterOptions() {
      return this.filterOptions.length == 1 && this.filterOptions[0] == "all"
        ? false
        : true;
    },
    defaultAlarmLevelOptions() {
      return this.$root.config.alarm.levels || [];
    },
    alarmLevelOptions() {
      let levels = this.$utils.distinct(
        (this.alarmList || [])
          .map(({ level }) => (level || "").toUpperCase())
          .filter((level) => level != "")
      );
      return levels.map((level) => {
        let option = (
          this?.contract?.portal_data?.alarm_level_options || []
        ).find((item) => level == item.label.value.toUpperCase());
        if (!option) {
          option = defaultAlarmLevelOption();
          option.label.value = level;
        }
        return option;
      });
    },
    isEquipmentDashboard() {
      return this.parentName == "EquipmentAlarmPanel";
    },
    parentName() {
      return this?.$parent?.$options?.name || "";
    },
    canShowAlarmList() {
      return this?.panelOptions?.allAlarms && this.mode == "editor"
        ? false
        : true;
    },
    filteredAlarms() {
      if (!this.canShowAlarmList) return [];
      // var ts = new Date().getTime();
      let lst = this.alarmList || [];
      if (this.filterOption != "all") {
        lst = lst.filter((item) => {
          if (this.filterOption == "enabled" && item.enabled) return true;
          if (this.filterOption == "disabled" && !item.enabled) return true;
          if (this.filterOption == "attention" && item.enabled) {
            if (
              item.alarm_current_state?.state || // alarmed:
              (item.acknowledgment_enabled && // require acknowledgment
                (item.alarm_current_state.alarm_state.number ==
                  alarmDefs.alarm_unack ||
                  item.alarm_current_state.alarm_state.number ==
                    alarmDefs.alarm_ret_unack))
            ) {
              return true;
            }
          }
          if (
            this.filterOption == "unacknowledgement" &&
            item.ackStatus == "waiting_ack"
          )
            return true;
          if (
            this.filterOption == "alarmed" &&
            (item.alarm_current_state?.state || false)
          )
            return true;
          return false;
        });
      }
      if (this.inputSearch) {
        if (this.query_string) {
          lst = lst.filter((item) =>
            this.$utils.queryStrAtr(this.query_string, item)
          );
        }
        if (this.query_level) {
          // lst = lst.filter((item) =>
          //   this.$utils.queryStrAtr(this.query_level.label.value, item, "level")
          // );
          lst = lst.filter(
            (item) => this.query_level.label.value == item.level
          );
        }
      }
      if (this.$store.getters.deviceId) {
        lst = lst.filter(
          ({ device_id }) => device_id == this.$store.getters.deviceId
        );
      }
      // console.log(new Date().getTime() - ts);
      return lst;
    },
    filteredAlarmListId() {
      return (this.filteredAlarms || []).map(({ id }) => id);
    }
  },
  watch: {
    filteredAlarmListId: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        this.$emit("filtered", n);
      },
      immediate: true
    }
  },
  methods: {
    alarmLevelOptionLabel(o) {
      let isDefault = (this.defaultAlarmLevelOptions || []).some(
        (d) => d?.label?.value == o?.label?.value
      );
      let label = isDefault
        ? this.$t(`alarm_level.${o?.label?.value}`)
        : o?.label?.value || "-";
      return label.toUpperCase();
    },
    resetQuery() {
      this.query_string = "";
      this.query_level = null;
      this.$nextTick(() => {
        if (this.$refs.query_string) {
          this.$refs.query_string.focus();
        }
      });
    }
  }
};
</script>

<style scoped>
.filter-options {
  margin: 0 0.5em;
}

.filter-options-small {
  font-size: 90%;
}

.filter-options *:not(:last-child) {
  margin-right: 0.8em;
}

.filter-option {
  display: inline-block;
  max-width: 100%;
  font-weight: 400;
}

.filter-option.active {
  color: #316ea2;
}

.input-search {
  position: relative;
  margin-bottom: 0;
  margin-top: -5px;
}

.input-search input {
  background-color: transparent;
  margin-right: 22px;
}

.input-search .input-reset {
  position: absolute;
  width: 20px;
  right: 0px;
  top: 5px;
  z-index: 4;
}
.dropdown-toggle {
  padding: 0 8px;
  margin: 0;
  background-color: transparent;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-menu > li > a > i {
  width: 16px;
  text-align: center;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
