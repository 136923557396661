<template>
  <section>
    <div class="row">
      <div class="col-lg-8 col-lg-offset-2">
        <div class="row">
          <div class="box" v-if="display">
            <div class="box-header" v-if="equipment">
              <div class="h4 no-vertical-margins">
                <div class="pull-left">
                  <div v-if="equipment.is_connected" class="text-success">
                    <i class="fa fa-check"></i> {{ equipment.name }}
                  </div>
                  <div v-else class="text-danger">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{ equipment.name }}
                  </div>
                </div>
                <div class="pull-right">
                  <small v-if="equipment.serial_number">
                    S/N: {{ equipment.serial_number }} -
                    {{ equipment.localizacao }}
                  </small>
                  <small v-else>
                    {{ equipment.localizacao }}
                  </small>
                </div>
              </div>
            </div>
            <div v-if="alarmListReady" class="box-content">
              <EquipmentAlarmPanel
                v-if="alarmList.length"
                v-bind:display="display"
                v-bind:equipment="equipment"
                v-bind:tab_id="tab_id"
                :panel="panel"
                maxHeight="unset"
              />
              <div v-else style="padding: 20px 50px 50px 50px">
                <div class="alert alert-default alert-dismissible">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="text-center">
                    <h4>{{ $t("alarm_list") }}</h4>
                    {{ $t("there_are_no_items_configured") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Spin v-else />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Spin from "@/components/spin.vue";
import EquipmentDashboardBase from "@/views/private/DashboardEquipmentBase.vue";
import EquipmentAlarmPanel from "@/components/equipment-alarm-panel";
export default {
  name: "DashboardAlarms",
  props: {
    screenId: {
      type: Number,
      required: false,
      default: 1000000000
    }
  },
  extends: EquipmentDashboardBase,
  components: {
    EquipmentAlarmPanel,
    Spin
  },
  data() {
    return {
      alarmListReady: false
    };
  },
  computed: {
    tab_id() {
      let id = parseInt(
        this.$route.query.alarm || this.$route.query.alarm_id || 0
      );
      let alarm = id
        ? this.alarmList.find(({ id }) => parseInt(id) == id)
        : null;
      return alarm && alarm.history_enabled ? 0 : 1;
    },
    panel() {
      return (this?.display?.panels || []).find(({ name }) => name == "alarms");
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    alarmList() {
      return (this.$store.getters["dashboard/alarmList"] || []).filter(
        ({ connector_id }) =>
          connector_id == this.equipmentId || connector_id == this.connectorId
      );
    }
  },
  methods: {
    fetchAlarmList() {
      var query = {
        resource: "alarm",
        connectorId: this.connectorId,
        forceUpdate: false,
        once: true
      };
      this.$store.dispatch("dashboard/fetchResourcesFrom", query).then(() => {
        this.alarmListReady = true;
      });
    }
  },
  created() {
    if (this.alarmList.length) {
      this.alarmListReady = true;
    } else {
      this.fetchAlarmList();
    }
  }
};
</script>

<style scoped>
.no-vertical-margins {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 5px;
}
.alert-default {
  background-color: whitesmoke;
}
div.box-content::v-deep > section > div.box {
  padding: 0 5px;
}
</style>
