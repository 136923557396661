<template>
  <div>
    <template v-if="alarms && alarms.length">
      <SearchableTable
        :key="maxResult"
        :fields="fields"
        :items="orderedAlarms"
        :searchEnabled="false"
        :pagination="pagination"
        :maxResult="maxResult"
        tableClass="table table-condensed table-responsive"
      >
        <template #connector_name="entry" v-if="!isEquipmentDashboard">
          <router-link
            class="cell-link clicable"
            :title="$t('titles.access_dashboard')"
            :to="`/dashboard/equipment/${entry.item.connector_id}`"
          >
            {{ entry.item.connector_name.toUpperCase() }}
            <i class="fa fa-dashboard"></i>
          </router-link>
        </template>
        <template #status="entry">
          <span class="alarm-state" v-if="entry.item.alarm_current_state.state">
            <i class="fa fa-exclamation-triangle"></i>
            {{ $t("alarmed") }}
          </span>
          <span class="alarm-state" v-else>
            <i class="fa fa-check"></i>
            {{ $t("normal") }}
          </span>
        </template>
        <template #lastTransition="entry">
          <span>{{ $dt.format(entry.item.lastTransition) }}</span>
        </template>
        <template #ackStatus="entry">
          <button
            v-if="entry.item.ackStatus != '_unknown'"
            type="button"
            data-testid="alarm-ack"
            class="btn btn-xs btn-default large-text no-wrap"
            v-on:click="acknowledgementButtonClicked(entry.item)"
            v-bind:class="
              !entry.item.enabled ? 'ack_disabled' : entry.item.ackStatus
            "
            v-bind:disabled="
              ack_status[entry.item.ackStatus].disabled || !entry.item.enabled
            "
            v-bind="
              entry.item.enabled
                ? {title: $t(ack_status[entry.item.ackStatus].title)}
                : {}
            "
          >
            <i
              class="fa"
              v-bind:class="ack_status[entry.item.ackStatus].icon"
            ></i>
          </button>
        </template>
        <template #current_value="entry">
          <DataValueDisplay
            v-if="entry.item.data"
            @commandRemoved="commandRemoved"
            :entry="entry.item.data"
            :zeroAsDash="false"
            :key="`${entry.item.id}${
              entry.item.data.pending_command ||
              entry.item.data.pending_mapping_write
                ? 'p'
                : 'u'
            }`"
          />
          <span v-else>
            <i
              v-if="!dataList || !dataList.length"
              class="fa fa-refresh fa-spin"
            ></i>
          </span>
        </template>
        <template #criteria_order="entry">
          <div class="alarm-condition">
            {{ entry.item.condition }}
            <DataValueDisplay
              v-if="entry.item.data"
              :title="`${entry.item.limit} ${
                entry.item.data.unity_label || ''
              }`"
              :entry="entry.item.limitData"
              :zeroAsDash="false"
              :key="`${entry.item.id}${
                entry.item.data.pending_command ||
                entry.item.data.pending_mapping_write
                  ? 'p'
                  : 'u'
              }`"
              style="width: auto"
            />
          </div>
        </template>
        <template #level="entry">
          <AlarmLevelDisplay :alarm="entry.item" />
        </template>
        <template #ack_enabled="entry">
          <span v-if="entry.item.acknowledgment_enabled">{{
            $tc("enabled")
          }}</span>
          <span v-else class="text-danger">{{ $tc("disabled") }}</span>
        </template>
        <template #notification="entry">
          <button
            type="button"
            class="btn btn-xs btn-default large-text"
            v-on:click="notificationButtonClicked(entry.item)"
            v-bind:title="$t('notification')"
          >
            <i class="fa fa-share-square"></i>
          </button>
        </template>
        <template #configure="entry">
          <button
            type="button"
            class="btn btn-xs btn-default large-text"
            v-on:click="edit(entry.item)"
            v-bind:title="$t('hints.access_to_configuration_form')"
          >
            <i class="fa fa-wrench"></i>
          </button>
        </template>
      </SearchableTable>
    </template>
    <template v-else>
      <slot name="no_alarms">
        <div
          class="alert text-center"
          style="background-color: whitesmoke; margin-top: 25px"
        >
          {{ $t("there_are_no_alarms_configuration") }}
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
import DataValueDisplay from "@/components/data-value-display.vue";
import AlarmLevelDisplay from "@/components/alarm-level-display.vue";
import {defAlarmFields} from "@/components/control-sidebar/property-editors/detail-form-alarm.vue";
export default {
  name: "EquipmentAlarmConfigurationTable",
  components: {
    DataValueDisplay,
    SearchableTable,
    AlarmLevelDisplay
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    alarms: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    panelOptions: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean,
      required: false,
      default: true
    },
    hiddenFields: {
      type: Array,
      required: false,
      default: () => []
    },
    isEquipmentDashboard: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  //inject: ["panel"],
  data() {
    return {
      alarm_unack: 1,
      alarm_ack: 2,
      alarm_ret_unack: 3,
      command_wait: 0,
      sort_def: {
        column: "",
        asc: true,
        map: {
          status: "status_order",
          name: "name",
          last_event: "lastTransition",
          criteria: "criteria_order"
        }
      },
      ack_status: {
        waiting_ack: {
          disabled: false,
          icon: ["fa-circle-o"],
          title: "ack_required"
        },
        waiting: {
          disabled: true,
          icon: ["fa-refresh", "fa-spin"],
          title: "processing"
        },
        ack_disabled: {
          disabled: true,
          icon: ["fa-ban"],
          title: "no_ack_required"
        },
        normal: {
          disabled: true,
          icon: ["fa-check-circle-o"],
          title: "normal"
        },
        acknowledged: {
          disabled: true,
          icon: ["fa-check-circle-o"],
          title: "acknowledged_alarm"
        }
      },
      minimalFields: [
        "name",
        "current_value",
        "criteria_order",
        "level",
        "ack_enabled",
        "notification"
      ]
    };
  },
  computed: {
    pagination() {
      let pageSize = this?.$root?.config?.equipment_selection?.page_size || 0;
      return this?.panelOptions?.pagination && pageSize ? true : false;
    },
    maxResult() {
      return this?.alarms?.length;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    fields() {
      let lst = JSON.parse(
        JSON.stringify(this?.panelOptions?.fields || defAlarmFields())
      );
      if (!this.full) {
        lst = (lst || [])
          .filter(({name}) => this.minimalFields.indexOf(name) >= 0)
          .map((f) => {
            f.visible = true;
            return f;
          });
      }
      lst = lst
        .filter(({name}) => this.hiddenFields.indexOf(name) == -1)
        .map((f) => {
          if (f.name == "configure" || f.name == "notification") {
            f.sortable = false;
          } else if (f.name == "current_value") {
            f.parser = (item) => {
              return item?.data?.current_value?.value || null;
            };
          } else if (f.name == "name") {
            f.parser = (item) => item.name.toUpperCase();
            f.hint = (item) => {
              if (item.data?.identity_embedded_app) {
                return `${this.$tc("data", 1)}: ${item.data.name}\n${this.$t(
                  "identity_embedded_app"
                )}: ${item.data?.identity_embedded_app}`;
              } else {
                return `${this.$tc("data", 1)}: ${item.data.name}`;
              }
            };
          }
          return f;
        });

      return lst;
    },
    // initial ordenation
    defaultOrder() {
      let lst = [...this.alarms];
      let now = new Date().getTime();
      // Options are:
      // priority | data_list | data_name | alarm_name | activation_datetime;
      let order = this?.panelOptions?.defaultOrder || "priority";
      let dataIdList = (this?.panelOptions?.dataList || []).map(
        ({data_id}) => data_id
      );
      const sortFn = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
      let fn = null;
      switch (order) {
        case "data_list":
          fn = (a, b) => {
            let ax = (dataIdList || []).indexOf(a.data_id);
            let bx = (dataIdList || []).indexOf(b.data_id);
            return sortFn(ax, bx);
          };
          break;
        case "data_name":
          fn = (a, b) => {
            let ax = this.dataList.find(({id}) => id == a.data_id)?.name || "";
            let bx = this.dataList.find(({id}) => id == b.data_id)?.name || "";
            return sortFn(ax.toLowerCase(), bx.toLowerCase());
          };
          break;
        case "alarm_name":
          fn = (a, b) => {
            return sortFn(a.name.toLowerCase(), b.name.toLowerCase());
          };
          break;
        case "activation_datetime":
          fn = (a, b) => {
            var ats = a?.alarm_current_state?.datetime_last_activation
              ? new Date(
                  a.alarm_current_state.datetime_last_activation || ""
                ).getTime()
              : now;
            var bts = b?.alarm_current_state?.datetime_last_activation
              ? new Date(
                  b.alarm_current_state.datetime_last_activation || ""
                ).getTime()
              : now;
            return sortFn(ats, bts);
          };
          break;
        default:
          // priority
          fn = (a, b) => {
            var ats = a?.alarm_current_state?.datetime_last_activation
              ? new Date(
                  a.alarm_current_state.datetime_last_activation || ""
                ).getTime()
              : now;
            var bts = b?.alarm_current_state?.datetime_last_activation
              ? new Date(
                  b.alarm_current_state.datetime_last_activation || ""
                ).getTime()
              : now;
            var ax = `${a.status_order}*${bts}`;
            var bx = `${b.status_order}*${ats}`;
            return sortFn(ax, bx);
          };
      }
      return fn ? lst.sort(fn) : lst;
    },
    // user manually triggered the ordernation
    columnOrder() {
      // let lst = [...this.alarms];
      let lst = this.alarms;
      let asc = this.sort_def.asc;
      let column = this.sort_def.column; // if it was defined in the component, take that
      let attr =
        column && column in this.sort_def.map
          ? this.sort_def.map[column]
          : this.sort_def.column; // mabe it has another name, so take that
      if (column == "status") {
        return lst.sort((a, b) => {
          let akey =
            (a?.alarm_current_state?.state
              ? asc
                ? "1"
                : "0"
              : asc
              ? "0"
              : "1") + a.lastTransition;
          let bkey =
            (b?.alarm_current_state?.state
              ? asc
                ? "1"
                : "0"
              : asc
              ? "0"
              : "1") + b.lastTransition;
          if (bkey > akey) return 1;
          if (akey > bkey) return -1;
          return 0;
        });
      }
      return lst.sort((a, b) => {
        if (attr in a && attr in b) {
          if (asc ? a[attr] > b[attr] : b[attr] > a[attr]) return 1;
          if (asc ? b[attr] > a[attr] : a[attr] > b[attr]) return -1;
        }
        return 0;
      });
    },
    orderedAlarms() {
      return this.sort_def.column ? this.columnOrder : this.defaultOrder;
    },
    notification() {
      var options = this.panelOptions;
      if (options && "notification" in options) {
        return options.notification.enabled;
      }
      return false;
    },
    acknowledgement() {
      var options = this.panelOptions;
      if (options && "acknowledgement" in options) {
        if (
          "enabled" in options.acknowledgement &&
          options.acknowledgement.enabled == false
        ) {
          return null;
        }
        if (this.$can("manage", "AlarmeReconhecimento")) {
          return options.acknowledgement;
        }
      }
      return null;
    },
    editor() {
      var options = this.panelOptions;
      if (options && "editor" in options) {
        return options.editor.enabled && this.$can("manage", "AlarmeCadastro");
      }
      return false;
    },
    showLine() {
      var options = this.panelOptions;
      if (options && "showLine" in options) {
        return options.showLine;
      }
      return true;
    },
    dataList() {
      // return this.$store.getters["dashboard/dataList"]
      //   ? this.$store.getters["dashboard/dataListFromEquipment"] || []
      //   : [];
      return this.$store.getters["dashboard/dataList"] || [];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    deviceList() {
      return this.$store.getters["dashboard/deviceList"] || [];
    }
  },
  watch: {
    alarms(list) {
      if (
        list.length &&
        this.$refs.table?.clientWidth > this.$el.clientWidth &&
        this.$refs.table?.clientHeight <=
          parseInt(this.panel.style["min-height"] ?? 0)
      ) {
        this.$emit("widthError", true);
      } else {
        this.$emit("widthError", false);
      }
    }
  },
  methods: {
    reorder(attr) {
      this.sort_def.asc =
        attr == this.sort_def.column ? !this.sort_def.asc : true;
      this.sort_def.column = attr;
    },
    acknowledgementButtonClicked(alarm) {
      if (this.$can("manage", "AlarmeReconhecimento")) {
        this.$emit("select", alarm);
      }
    },
    notificationButtonClicked(alarm) {
      this.$emit("notificationButtonClicked", alarm);
    },
    edit(alarm) {
      this.$emit("editButtonClicked", alarm);
    },
    commandRemoved(commandId) {
      let alarm, lst;
      for (var i in this.alarms || []) {
        alarm = this.alarms[i];
        let len = (alarm && (alarm?.data?.pending_commands || []).length) || 0;
        if (len) {
          lst = alarm.data.pending_commands.filter(({id}) => id != commandId);
          if (lst.length < len) {
            let data = JSON.parse(JSON.stringify(alarm.data));
            data.pending_commands = lst;
            let entry = {
              alarm_id: alarm.id,
              data: data
            };
            this.$store.dispatch("dashboard/setAlarmValue", entry);
            // do not return since it might happen to have more than one alarm with same pending data command
          }
        }
      }
    }
  }
};
</script>

<style scoped>
a:hover {
  cursor: pointer;
}
.table-container {
  /* overflow-x: auto; */
}

.table > tbody > tr > td {
  vertical-align: middle !important;
  padding: 2px 5px !important;
}

.table > tbody > tr > td > div.alarm-condition {
  white-space: nowrap;
  width: 100%;
}

button.large-text,
button.disabled {
  opacity: 1;
  margin: -2px 0 !important;
}

button.large-text:hover {
  cursor: pointer;
}

button:disabled:hover {
  cursor: not-allowed;
}

button.large-text > i {
  font-size: 2rem;
  padding-top: 2px;
}

button.ack_disabled {
  color: gray;
}
button.waiting {
  color: #f58a7d;
}
button.waiting_ack {
  color: #f39c12;
}
button.acknowledged {
  color: #00a65a;
}
button.normal {
  color: #00a65a;
}
.alarm-name {
  max-width: 280px;
  white-space: normal;
}
.alarm-state {
  white-space: nowrap;
}
.disabled *:not(:last-child) {
  color: #777;
  opacity: 0.8;
}

.cell-link {
  color: inherit;
}
.cell-link > i {
  margin: 0 5px;
  color: transparent;
}
.cell-link:hover,
.cell-link:hover > i {
  color: #337ab7;
  font-weight: 600;
}
</style>

<style>
tr.alarm-item {
  color: black;
}
tr.alarm-item.bg-alarm-active {
  background-color: #f58a7d;
}
tr.alarm-item.bg-ack-needed {
  background-color: #f39c12;
}
tr.alarm-item.disabled {
  opacity: 0.8;
  color: #777;
}
</style>
