import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    tab: {
      data_history_usage_notifications: "Uso do Histórico de Dados"
    },
    hints: {
      data_history_usage_notifications: "Alertas sobre consumo de registros históricos próximo aos limites estabelecidos"
    },
    error_labels: pt
  },
  en: {
    tab: {
      data_history_usage_notifications: "Data usage"
    },
    hints: {
      data_history_usage_notifications: "Alerts regarding consumption of historical records close to established limits"
    },
    error_labels: en
  },
};
