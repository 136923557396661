<template>
  <div class="table-container" v-if="$can('view', 'HistoricoAlarmeAcesso')">
    <Spin v-if="busy" />
    <SearchableTable
      tableId="alarmHistoryReport"
      v-if="showTable"
      v-bind:items="filteredItems"
      v-bind:fields="fields"
      v-bind:multiColumnOrder="false"
      v-bind:clientSort="true"
      v-bind:pagination="pagination"
      v-bind:maxResult="maxResult"
      v-bind:searchEnabled="false"
    />
  </div>
</template>

<script>
import {isEqual, debounce} from "lodash";
import SearchableTable from "@/components/searchable-table.vue";
import AlarmService from "@/services/alarm.js";
import Spin from "@/components/spin.vue";

/*
{
    "date_time_event": "2021-01-02T10:32:10-03:00",
    "alarm_value": "1.000",
    "system_event_id": 3,
    "system_sub_event_id": 12,
    "alarm": {
        "id": 4191,
        "name": "ALARME COIL 0 (coleta local)"
    },
    "user_email": "suporte@hitecnologia.com.br"
}
*/
function defaultData(self) {
  return {
    busy: false,
    showTable: true,
    myStartDate: null,
    myEndDate: null,
    //===========================
    items: null,
    fields: [
      {
        name: "name",
        title: "alarm",
        order_id: "alarm_name",
        parser(item) {
          return item.alarm.name || "-";
        }
      },
      {
        name: "date_time_event",
        title: "timestamp",
        order_id: "date_time_event",
        parser: function (item) {
          if (item && item.date_time_event) {
            return self.$dt.format(item.date_time_event);
          }
          return "";
        }
      },
      {
        name: "event",
        title: "event",
        order_id: "system_sub_event_name",
        parser(item) {
          let txt = (item.system_sub_event && item.system_sub_event.name) || "";
          if (!txt) {
            let evt = (
              self.$root?.config?.references.system_event_list || []
            ).find(({id}) => id == item.system_event_id);
            let sev = ((evt && evt?.system_sub_event_list) || []).find(
              ({id}) => id == item.system_sub_event_id
            );
            txt = (sev && sev?.name) || "";
          }
          return txt;
        }
      },
      {
        name: "user",
        title: "user",
        order_id: "user_email",
        parser: function (item) {
          let email =
            (item && item.user_email) || (item.user && item.user.email) || "";
          if (email.indexOf("@") >= 0) {
            return (email || "").split("@")[0] + "@";
          }
          return "-";
        }
      },
      {
        name: "value",
        title: "value",
        order_id: "alarm_value",
        parser: function (item) {
          return item.alarm_value;
        },
        style: {
          "text-align": "right"
        }
      }
    ],
    next_page: null,
    curr_page: null,
    prev_page: null,
    sortOrder: null,
    maxResult: 0
  };
}

export default {
  name: "EquipmentAlarmHistoryDisplay",
  components: {
    SearchableTable,
    Spin
  },
  props: {
    equipment: {
      tyoe: Object,
      required: true
    },
    display: {
      type: Object,
      required: true
    },
    panelOptions: {
      type: Object,
      required: true
    },
    startDate: {
      type: Object,
      required: false,
      default: () => moment()
    },
    endDate: {
      type: Object,
      required: false,
      default: () => moment()
    },
    downloading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    alarmList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return defaultData(this);
  },
  computed: {
    pagination() {
      return "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
        ? true
        : false;
    },
    contract() {
      let user = this.$store.getters["user/loggedUser"];
      return (user && user.contract) || null;
    },
    isDirty: function () {
      return (
        this.startDate &&
        this.endDate &&
        this.startDate._d &&
        this.endDate._d &&
        (this.myStartDate != this.startDate._d ||
          this.myEndDate != this.endDate._d)
      );
    },
    showLine: function () {
      var options = this.panelOptions;
      if (options && "showLine" in options) {
        return options.showLine;
      }
      return false;
    },
    historyEnabledAlarmIdList() {
      return this.alarmList
        .filter(({history_enabled}) => history_enabled)
        .map(({id}) => id);
    },
    filteredItems() {
      let self = this;
      if (!this.historyEnabledAlarmIdList.length) return [];
      let lst = this.items || [];
      let device_id = self.$store.getters.deviceId;
      if (device_id && lst && lst.length) {
        let alarms = {};
        lst = lst.filter((i) => {
          let alarm =
            alarms[i.alarm.id] ||
            this.alarmList.find((alarm) => alarm.id == i.alarm.id) ||
            null;
          if (alarm) {
            alarms[i.alarm.id] = alarm;
          }
          return alarm && alarm.device_id == device_id;
        });
      }
      return lst.filter(
        ({alarm}) => this.historyEnabledAlarmIdList.indexOf(alarm.id) >= 0
      );
    }
  },
  watch: {
    isDirty: {
      handler: function (n, o) {
        if (n && !o && !isEqual(n, o)) {
          this._delayedFetch();
        }
      },
      immediate: true,
      deep: true
    },
    historyEnabledAlarmIdList(n, o) {
      if (n && o && !isEqual(n, o)) {
        this._delayedFetch();
      }
    }
  },
  methods: {
    fetch() {
      if (
        !(
          this.startDate &&
          this.endDate &&
          this.startDate._d &&
          this.endDate._d
        )
      )
        return;
      this.myStartDate = this.startDate._d;
      this.myEndDate = this.endDate._d;
      let query = {};
      query.contract_id = this?.contract?.id;
      query.start = moment(this.startDate).utc().format("YYYY-MM-DDTHH:mm:ss");
      query.end = moment(this.endDate).utc().format("YYYY-MM-DDTHH:mm:ss");
      if (this.historyEnabledAlarmIdList.length) {
        query.alarm_ids = this.historyEnabledAlarmIdList.join(",");
      } else {
        query.connector_id = this.equipment.id;
      }
      this.busy = true;
      this.service.history(query).then((response) => {
        this.busy = false;
        if (response && typeof response == "object") {
          this.$set(this, "items", response);
          this.maxResult = this.items.length;
        }
      });
    },
    reset() {
      Object.assign(this.$data, defaultData(this));
    }
  },
  beforeCreate() {
    this.service = new AlarmService();
    this._delayedFetch = debounce(() => {
      this.fetch();
    }, 300);
  },
  created: function () {
    // this.fetchItems();
  },
  mounted: function () {
    //this.fetchEquipmentDataAlarmsHistory();
  }
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
  height: 100%;
}
</style>
