<template>
  <form class="form-inline" v-on:submit.prevent="submit">
    <div class="form-group pull-left">
      <label
        for="timeout"
        style="margin-right:10px;"
        v-bind:title="
          $t('wait_for_alarm_recognition_timeout') +
            '\n' +
            $t('before_try_a_new_group')
        "
        >{{ $t("wait_for_alarm_recognition_timeout") }}</label
      >
      <div class="input-group">
        <input
          type="number"
          name="timeout"
          class="text-right form-control"
          required
          v-model="notification.timeout"
        />
        <div class="input-group-addon">{{ notification.unit }}</div>
      </div>
    </div>
    <div class="form-group pull-right">
      <button
        class="btn btn-default"
        v-on:click.prevent.stop="cancel"
        style="margin-right:15px;"
      >
        {{ $t("restore") }}
      </button>
      <button class="btn btn-primary">
        <span v-if="save">
          {{ $t("save") }}
        </span>
        <span v-else>
          {{ $t("confirm") }}
        </span>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "NotificationInlineForm",
  props: {
    timeout: {
      type: Number,
      required: false,
      default: () => 5
    },
    groups: {
      type: Array,
      required: false,
      default: () => []
    },
    save: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      notification: {
        timeout:
          ("notifications" in this.$root.config.equipment_extended_properties &&
            this.$root.config.equipment_extended_properties.notifications
              .timeout) ||
          5,
        unit:
          ("notifications" in this.$root.config.equipment_extended_properties &&
            this.$root.config.equipment_extended_properties.notifications
              .unit) ||
          "m"
      }
    };
  },
  methods: {
    cancel: function() {
      this.$emit("cancel");
    },
    submit: function() {
      this.$emit("submit", {
        groups: this.groups, // the main one is the correct one
        timeout: parseInt(this.notification.timeout)
      });
    }
  },
  created: function() {
    this.notification.timeout = parseInt(this.timeout);
  }
};
</script>

<style lang="scss" scoped></style>
