<script>
export default {
  name: "DataValue",
  props: {
    entry: {
      type: Object,
      required: false,
      default: () => null
    },
    zeroAsDash: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  computed: {
    dataFormat() {
      return this.$root.$formatter.dataFormat(this.entry);
    },
    text_list_id() {
      return this.$root.$formatter.textListId(this.entry);
    },
    text_list() {
      return this.$root.$formatter.textList(this.entry);
    },
    defaultTextListValue() {
      return this.$root.$formatter.defaultTextListValue(this.entry);
    },
    memorySize() {
      return this?.entry?.memory_size || 1;
    },
    dataValueIndex() {
      return this?.entry?.portal_data?.data_value_index;
    },
    dataValueCurrentIndex() {
      if (!(this.memorySize > 1) || !this.dataValueIndex) return -1;
      let vlr = -1;
      if (this.dataValueIndex.type == "constant") {
        vlr =
          this.dataValueIndex.value === ""
            ? -1
            : this.dataValueIndex.value ?? -1;
      } else if (this.dataValueIndex.data_id) {
        let data = this.$store.getters["dashboard/dataList"].find(
          ({id}) => parseInt(id) == parseInt(this.dataValueIndex.data_id)
        );
        vlr = (data && data?.current_value?.value) ?? -1;
      }
      return vlr >= 0
        ? vlr +
            parseInt(
              this?.dataValueIndex?.offset === ""
                ? 0
                : this?.dataValueIndex?.offset ?? 0
            )
        : -1;
    },
    rawValue() {
      return this.dataValueCurrentIndex < 0
        ? this.$root.$formatter.rawValue(this?.entry)
        : this.$root.$formatter.rawValue(
            this?.entry,
            this.dataValueCurrentIndex
          );
    },
    dataValue() {
      // return this.$root.$formatter.rawValue(this.entry);
      return this.rawValue;
    },
    // dataValue: function(){}
    formatedDataValue() {
      return this.$root.$formatter.format(this.entry);
    },
    state() {
      return this.$root.$formatter.state(this.entry);
    },
    isValid() {
      return this.validate(this.rawValue);
    },
    text_list_items() {
      // convert to array since backend returns the list as object
      let lst = [];
      for (var i in this?.text_list?.items || {}) {
        lst.push({id: i, name: this.text_list.items[i]});
      }
      return lst;
    }
  },
  watch: {
    state: {
      handler(n, o) {
        if (n) {
          if (o && o.id == n.id) return;
          this.$emit("stateChanged", n);
        } else {
          if (o) {
            this.$emit("stateChanged", null);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    // returns the string message of the current error
    validate(value) {
      // TODO: add more parsers and rules
      let parsers = {
        out_of_range: this.hasIntervalError
      };
      for (var k in parsers) {
        if (parsers[k](value)) {
          return k;
        }
      }
      return "";
    },
    hasIntervalError(value) {
      if (value !== "") {
        return (
          (!isNaN(Number(this.entry?.minimum_value)) &&
            value < parseFloat(this.entry?.minimum_value)) ||
          (!isNaN(Number(this?.entry?.maximum_value)) &&
            value > parseFloat(this.entry?.maximum_value))
        );
      }
      return false;
    }
  }
};
</script>
