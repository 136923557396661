<template>
  <section>
    <draggable class="list-group" v-model="fields">
      <div v-for="(item, ix) in fields" v-bind:key="ix">
        <div class="list-group-item item group-item">
          <div class="move-item">
            <span class="move-icon">
              <i class="fa fa-arrows-v"></i>
            </span>
            <span :class="{ disabled: !item.visible }">
              {{ $tc(item.title, 1) }}
            </span>
            <span class="item-buttons">
              <span
                class="btn btn-xs"
                v-on:click.prevent.stop="item.visible = !item.visible"
              >
                <i class="fa fa-eye" v-if="item.visible"></i>
                <i class="fa fa-eye-slash" v-else></i>
              </span>
            </span>
          </div>
        </div>
      </div>
    </draggable>
  </section>
</template>

<script>
import { isEqual } from "lodash";
import draggable from "vuedraggable";
export default {
  name: "FieldListForm",
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    fields: [
      // {
      //   name: "name",
      //   title: "alarm",
      //   visible: true,
      //   style: {
      //     "text-align": "left"
      //   }
      // },
      // {
      //   name: "status",
      //   title: "status",
      //   visible: true,
      //   style: {
      //     "text-align": "left"
      //   }
      // },
      // {
      //   name: "lastTransition",
      //   title: "last_event",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "ackStatus",
      //   title: "ack",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "current_value",
      //   title: "current_value",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "criteria_order",
      //   title: "criteria",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "nivel",
      //   title: "level",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "ack_enabled",
      //   title: "acknowledgement",
      //   visible: false,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "notification",
      //   title: "not",
      //   visible: false,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "configure",
      //   title: "configure",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // }
    ]
  }),
  watch: {
    value: {
      handler(n) {
        if (n && !isEqual(n, this.fields)) {
          this.$set(this, "fields", JSON.parse(JSON.stringify(n)));
        }
      },
      deep: true,
      immediate: true
    },
    fields: {
      handler(n) {
        if (n && !isEqual(n, this.value)) {
          this.$emit("input", n);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.group-item {
  padding: 0;
  line-height: 30px;
}

.move-item:hover {
  cursor: move;
  opacity: 0.8;
}

.move-item > span.disabled {
  color: #999;
}

.move-item > .move-icon {
  margin: 0 5px;
  color: #999;
}

.move-item > .item-buttons {
  position: absolute;
  z-index: 1;
  right: 0;
  vertical-align: middle;
}

.move-item > .item-buttons > span {
  vertical-align: middle;
}
</style>
