<template>
  <span class="level" v-if="alarm && alarm.level">
    <span
      v-if="alarmLevelOptionIcon"
      class="level-icon"
      :class="alarmLevelOptionIcon.class"
      :style="alarmLevelOptionIcon.style"
    ></span>
    <span
      class="level-icon"
      :style="
        alarmLevelOption && alarmLevelOption.label.style
          ? alarmLevelOption.label.style
          : ''
      "
    >
      {{ alarmLevelOptionLabel }}
    </span>
  </span>
</template>

<script>
import { defaultAlarmLevelOption } from "@/components/registration/alarm-level-form.vue";
export default {
  name: "AlarmLevelDisplay",
  props: {
    alarm: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    defaultAlarmLevelOptions() {
      return this.$root.config.alarm.levels || [];
    },
    alarmLevelOptions() {
      return this?.contract?.portal_data?.alarm_level_options || [];
    },
    alarmLevelOption() {
      if (!this?.alarm) return null;
      let option = null;
      if (this.alarm.level && this?.alarmLevelOptions?.length) {
        option = this?.alarmLevelOptions.find(
          ({ label }) =>
            label.value.toUpperCase() == this.alarm.level.toUpperCase()
        );
        option = option ? JSON.parse(JSON.stringify(option)) : null;
      }
      if (!option) {
        option = defaultAlarmLevelOption();
        option.label.value = this.alarm.level;
      }
      return option;
    },
    alarmLevelOptionLabel() {
      let o = this.alarmLevelOption;
      let isDefault = (this.defaultAlarmLevelOptions || []).some(
        (d) => d?.label?.value == o?.label?.value
      );
      let label = isDefault
        ? this.$t(`alarm_level.${o?.label?.value}`)
        : o?.label?.value || "-";
      return label.toUpperCase();
    },
    alarmLevelOptionIcon() {
      return this?.alarmLevelOption?.icon?.show
        ? this?.alarmLevelOption?.icon
        : null;
    }
  }
};
</script>

<style scoped>
.level > .level-icon {
  width: 18px;
  text-align: center;
}
.level > .level-label {
}
</style>
