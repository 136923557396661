import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    hints: {
      name: "Nome para identificação do alarm no portal",
      description: "Descrição para identificação do alarme no portal",
      acknowledgement: "O estado do alarme não voltará ao normal até que este seja explicitamente reconhecido",
      trigger_criteria: "Critério para ativação do alarme",
      level: "Texto associado ao nível do alarme (ex.: HIHI, LO, etc)",
      attention:"Exibe itens alarmados ou que necessitem reconhecimento",
      all: "Exibe todos os alarmes configurados",
      alarmed: "Exibe apenas itens alarmados",
      unacknowledgement: "Exibe apenas itens que requerem reconhecimento",
      disabled: "Exibe itens desabilitados",
      enabled: "Exibe itens habilitados",
      history: 'Habilita o histórico de valores do alarme'
    },
    error_labels: pt
  },
  en: {
    hints: {
      name: "Name for Alarm Identification on the Portal",
      description: "Description for alarm identification on the portal",
      acknowledgement: "O estado ativo do alarme não voltará ao normal até que este seja explicitamente reconhecido",
      trigger_criteria: "Critério para ativação do alarme",
      level: "Texto associado ao nível do alarme (ex.: HIHI, LO, etc)",
      attention:"Displays alarmed or unacknowledgement items",
      all: "All configured alarms",
      alarmed: "Displays alarmed items only",
      unacknowledgement: "Displays unacknowledgement items only",
      disabled: "Displays disabled items",
      enabled: "Displays enabled items",
      history: 'Habilita o histórico de valores do alarme'
    },
    error_labels: en
  },
};
