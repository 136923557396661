<template>
  <input
    ref="inp"
    type="text"
    :value="iValue"
    :maxlength="maxSize ? maxSize : 1024"
    @focus="setFocus(true)"
    @blur="setFocus(false)"
    @keydown="onKeyDown($event)"
    @input="setValue($event)"
    enterkeyhint="done"
  />
</template>

<script>
// input with IOs mobile support
export default {
  name: "InputString",
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: () => ""
    },
    focus: {
      type: Boolean,
      default: false,
      required: false
    },
    saveOnTab: {
      type: Boolean,
      default: true,
      required: false
    },
    maxSize: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data() {
    return {
      iValue: this.value,
      original: this.value,
      hasFocus: false
    };
  },
  watch: {
    hasFocus(n) {
      if (this.$el) {
        this.$emit(n ? "focus" : "blur");
        if (n) {
          this.iValue = this.$utils.trim(this.value);
        }
      }
    },
    value(n) {
      this.iValue = n;
    }
  },
  methods: {
    setValue($event) {
      if (this.hasFocus) {
        let value = this.$utils.trim($event.target.value);
        if (this.isValid(value)) {
          this.iValue = this.maxSize ? value.substring(0, this.maxSize) : value;
          this.notify();
        } else {
          var e = document.createEvent("Event");
          e.initEvent("input", true, true);
          e.target.value = e.target._value; // update the content of the element
          e.target.dispatchEvent(e); // trigger new event to update the model on vue event chain
        }
      }
    },
    // since it is using $el can not cache result
    isValid(value) {
      // TODO: import and accept regex / function validation tools
      return true;
    },
    setFocus(value) {
      this.hasFocus = value;
    },
    notify() {
      if (this.isValid()) {
        this.$emit("input", this.iValue);
      }
    },
    onKeyDown(e) {
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
      if (
        e.code == "Enter" ||
        e.code == "NumpadEnter" ||
        e.key == "Enter" ||
        e.key == "NumpadEnter"
      ) {
        if (this.isValid()) {
          this.$emit("keydown", e);
        }
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code == "Tab" || e.key == "Tab") {
        if ((this.$utils.isMobile() || this.saveOnTab) && this.isValid()) {
          this.$emit("keydown", e);
          e.preventDefault();
          e.stopPropagation();
        }
      } else if (e.code == "Escape" || e.key == "Escape") {
        this.$emit("keydown", e);
        e.preventDefault();
        e.stopPropagation();
      }
    }
  },
  mounted() {
    if (this.focus) {
      this.$nextTick(() => {
        if (this?.$refs?.inp?.focus) {
          this.$refs.inp.focus();
        }
      });
    }
  }
};
</script>
