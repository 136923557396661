<template>
  <DetailForm persistent="toggle_alarm_panel">
    <DetailFormAlarm />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormAlarm from "@/components/control-sidebar/property-editors/detail-form-alarm.vue";
export default {
  name: "AlarmForm",
  components: {
    DetailForm,
    DetailFormAlarm
  }
};
</script>
