// prettier-ignore
export default {
  pt: {
    clear_history: 'Remover registros históricos',
    data_only: 'Apenas de dados',
    alarms_only: 'Apenas de alarmes',
    reason: 'Motivo da remoção',
    datetime_filter_type: 'Tipo de Filtragem de Data',
    cutting_datetime: 'Data de corte',
    period_datetime: 'Período',
    no_datetime: 'Todos',
    start_datetime: 'Data Inicial',
    end_datetime: 'Data Final',
    clean: 'Tudo limpo',
    history_cleared: 'Dados históricos removidos com sucesso.',
    history_not_cleared: 'Um erro não permitiu que os dados históricos fossem removidos.',
    removing: 'Removendo...',
    awareness_message: {
      connector: 'Todos os registros armazenados do histórico de <strong>{resource}</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida. | Todos os registros armazenados dos históricos de <strong>dados e alarmes</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
      device: 'Todos os registros armazenados do histórico de <strong>{resource}</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida. | Todos os registros armazenados dos históricos de <strong>dados e alarmes</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
      data: 'Todos os registros armazenados do histórico de <strong>alarmes</strong> deste dado serão excluídos permanentemente. Esta ação não poderá ser revertida. | Todos os registros armazenados dos históricos <strong>deste dado e seus alarmes</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
      alarm: 'Todos os registros armazenados do histórico deste <strong>alarme</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
    },
    hints: {
      clear_history: {
        connector: 'Remova o histórico de dados e/ou alarmes deste conector',
        device: 'Remova o histórico de dados e/ou alarmes deste dispositivo',
        data: 'Remova o histórico deste dado e/ou de seus alarmes',
        alarm: 'Remova o histórico de disparos deste alarme',
      },
      reason: 'Especifique o motivo da exclusão do histórico',
      cutting_datetime: 'Todos os registros nesta data e anteriores a ela serão excluídos.',
      period_datetime: 'Todos os registros dentro deste período serão excluídos.',
      no_datetime: 'Todos os registros serão excluídos.'
    }
  },
  en: {
    clear_history: 'Remover dados históricos',
    data_only: 'Apenas de dados',
    alarms_only: 'Apenas de alarmes',
    reason: 'Motivo da remoção',
    datetime_filter_type: 'Tipo de Filtragem de Data',
    cutting_datetime: 'Data de corte',
    period_datetime: 'Período',
    no_datetime: 'Todos',
    start_datetime: 'Data Inicial',
    end_datetime: 'Data Final',
    clean: 'Tudo limpo',
    history_cleared: 'Dados históricos removidos com sucesso.',
    history_not_cleared: 'Um erro não permitiu que os dados históricos fossem removidos.',
    removing: 'Removendo...',
    awareness_message: {
      connector: 'Todos os registros armazenados do histórico de <strong>{resource}</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida. | Todos os registros armazenados dos históricos de <strong>dados e alarmes</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
      device: 'Todos os registros armazenados do histórico de <strong>{resource}</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida. | Todos os registros armazenados dos históricos de <strong>dados e alarmes</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
      data: 'Todos os registros armazenados do histórico de <strong>alarmes</strong> deste dado serão excluídos permanentemente. Esta ação não poderá ser revertida. | Todos os registros armazenados dos históricos <strong>deste dado e seus alarmes alarmes</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
      alarm: 'Todos os registros armazenados do histórico deste <strong>alarme</strong> serão excluídos permanentemente. Esta ação não poderá ser revertida.',
    },
    hints: {
      clear_history: {
        connector: 'Remova o histórico de dados e/ou alarmes deste conector',
        device: 'Remova o histórico de dados e/ou alarmes deste dispositivo',
        data: 'Remova o histórico deste dado e/ou de seus alarmes',
        alarm: 'Remova o histórico de disparos deste alarme',
      },
      reason: 'Especifique o motivo da exclusão do histórico',
      cutting_datetime: 'Todos os registros nesta data e anteriores a ela serão excluídos.',
      period_datetime: 'Todos os registros dentro deste período serão excluídos.',
      no_datetime: 'Todos os registros serão excluídos.'
    }
  },
}
